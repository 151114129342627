const commonMethods = {
    //  "SFYXYJ": //是否有效，1有效（不是误报）；
    // 查看
    showReadBtn() {
        return false;
    },
    // 处置按钮预警是误报的时候 不可点击
    showEditBtn() {
        return true;
    },
    // 地图定位只有处警数据有经纬度的时候可点击
    showMapBtn(row) {
        if (row.JD && row.WD) {
            return true;
        } else {
            return false;
        }
    },
    // 删除按钮只有预警是误报的时候可点击 SFYXYJ 1警报，0误报
    showDelBtn(row) {
        if (row.SFYXYJ === null || Number(row.SFYXYJ) === 1) {
            return false;
        } else {
            return true;
        }
    },
};
export { commonMethods };