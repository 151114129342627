<template>
    <div class="warning-plan-grid">
        <sn-table-group
            v-if="!isMapShow"
            class="table"
            :tableGroupAttributes="tableGroupAttributes"
            :commonMethods="commonMethods"
            @handleChange="handleChange"
        ></sn-table-group>
        <div v-if="isMapShow" class="warning-plan-map">
            <button class="back-btn" @click="BackToSbqdTable">返回</button>
            <WarningDisposeMap
                class="lonlat-map"
                :mapPoint="mapPoint"
            ></WarningDisposeMap>
        </div>
        <sn-dialog :dialogData.sync="dialogData">
            <template>
                <CjsjDetail :dataSource="currentData"></CjsjDetail>
            </template>
        </sn-dialog>
    </div>
</template>
<script>
import { commonData } from "./comp/sn-table-group-common";
import { selfData } from "./model/sn-table-group";
import commonMethodMixin from "./comp/commonMethodMixin";
import { mapActions } from "vuex";
import { commonMethods } from "./model/table-methods";
import WarningDisposeMap from "./map.vue";
import CjsjDetail from "./cjsj_detail.vue";
export default {
    name: "warning_dispose_grid",
    components: {
        WarningDisposeMap,
        CjsjDetail,
    },
    mixins: [commonMethodMixin],
    data() {
        return {
            commonData,
            commonMethods,
            selfData,
            infoPage: "warning_dispose_form",
            // 筛选项
            keyword: "", // 关键字
            yjdxValue: "", // 预警对象选择的值
            cjrValue: "", // 采集人选择的值
            yjztValue: "", // 预警状态选择的值
            startTime: "", // 开始时间
            endTime: "", // 结束时间
            exportCode: "",
            isMapShow: false, // 地图视图
            mapPoint: [],
            dialogData: {
                title: "触警数据",
                dialog: false,
                width: "",
                action: false,
            },
            currentData: {},
            exportFunId: "yj004",
            exportName: "预警处置",
        };
    },
    mounted() {
        this.getYjxList();
        // this.getUserList();
        this.getYjdjList();
    },
    methods: {
        ...mapActions([
            "GetYjsjDataList", // 获取预警处置列表
            "GetYjxList", // 获取触警项列表
            "GetUserList", // 获取人员列表
            "GetEnumIdList", // 获取预警状态枚举值
            "DeleteYjsjData", // 删除预警事件数据
            "GetCjsjData", // 获取触警数据
        ]),
        async getTableData() {
            // 处理筛选
            this.yjdxValue = "";
            this.cjrValue = "";
            this.yjztValue = "";
            let userId = "";
            if (localStorage.ROLEID !== window.ADMIN_ROLEID) {
                userId = localStorage.userId;
            }
            if (this.searchData && this.searchData.length > 0) {
                this.searchData.forEach((item) => {
                    if (
                        (typeof item.value === "string" && item.value !== "") ||
                        (typeof item.value === "object" &&
                            Object.keys(item.value).length > 0)
                    ) {
                        if (item.type === "YJDX") {
                            this.yjdxValue = item.value;
                        } else if (item.type === "CJRID") {
                            this.cjrValue = item.value;
                        } else if (item.type === "YJZT") {
                            this.yjztValue = item.value;
                        }
                    }
                });
            }
            if (this.dateSearchData && this.dateSearchData.length > 0) {
                this.dateSearchData.forEach((item) => {
                    if (!item.value) return;
                    if (
                        (typeof item.value === "string" && item.value !== "") ||
                        (typeof item.value === "object" &&
                            Object.keys(item.value).length > 0)
                    ) {
                        if (item.type === "startTime") {
                            this.startTime = item.value;
                        } else if (item.type === "endTime") {
                            this.endTime = item.value;
                        }
                    }
                });
            }

            this.tableGroupAttributes.loading = true;
            let res = await this.GetYjsjDataList({
                keyWords: this.keyword, // String 关键字；预案名称
                yjdxbm: this.yjdxValue, // 预警对象编码
                cjrId: userId, // this.cjrValue, // 采集人ID
                startTime: this.startTime, // 开始时间
                endTime: this.endTime, // 结束时间
                yjzt: this.yjztValue, // 预警状态，传枚举
                pageIndex: this.tableGroupAttributes.snPage.currentPage, // Int 页数
                pageSize: this.tableGroupAttributes.snPage.tiaoshu, // Int 页面数据量
            });
            this.tableGroupAttributes.snTable.table.data =
                res && res.DATA ? res.DATA : [];
            this.mapPoint = res && res.DATA ? res.DATA : [];
            this.tableGroupAttributes.snPage.count =
                res && res.TOTAL ? Number(res.TOTAL) : 0;
            this.tableGroupAttributes.loading = false;
        },
        // 获取触警项列表
        async getYjxList() {
            this.tableGroupAttributes.snSearch.selectData[0].list = [];
            let result = await this.GetYjxList();
            if (result && result.length > 0) {
                this.tableGroupAttributes.snSearch.selectData[0].list = result;
            }
        },
        // 获取人员列表
        // async getUserList() {
        //     this.tableGroupAttributes.snSearch.selectData[1].list = [];
        //     let res = await this.GetUserList({
        //         keywords: "",
        //         depID: "",
        //         roleid: "",
        //         pageindex: 1,
        //         pagesize: 99999
        //     });
        //     if (res && res.result && res.result.Data && res.result.Data.length > 0) {
        //         this.tableGroupAttributes.snSearch.selectData[1].list = res.result.Data;
        //     }
        // },
        // 获取预警等级列表
        async getYjdjList() {
            this.tableGroupAttributes.snSearch.selectData[1].list = [];
            let result = await this.GetEnumIdList({
                enumId: "300b56ab-67fc-4acb-9444-71daba0dc187",
            });
            if (result && result.length > 0) {
                this.tableGroupAttributes.snSearch.selectData[1].list = result;
            }
        },
        // 删除数据
        async delData(id) {
            let res = await this.DeleteYjsjData({
                ids: id,
            });
            if (res.ISSUCCESS) {
                this.common.showMsg(res.RESULTDESCRIPTION, "success");
                // this.tableGroupAttributes.snPage.currentPage = 1;
                this.getTableData();
            } else {
                this.common.showMsg(res.RESULTDESCRIPTION, "error");
            }
        },
        // 地图视图
        toMapPage(val) {
            if (val) {
                this.mapPoint = val;
            }
            this.isMapShow = true;
        },
        // 返回列表
        BackToSbqdTable() {
            this.isMapShow = false;
        },
        // 触警数据查看
        columnTextClick(val) {
            this.dialogData.dialog = true;
            this.currentData = val[0];
        },
    },
};
</script>
<style lang="scss" scoped>
.warning-plan-grid {
    width: 100%;
    height: 100%;
    .table {
        width: 100%;
    }
    .warning-plan-map {
        position: relative;
        width: 100%;
        height: 100%;
        .back-btn {
            position: absolute;
            top: 10px;
            right: 60px;
            z-index: 401;
            width: 100px;
            height: 30px;
            color: #fff;
            background-color: rgb(40, 204, 217);
            border: 1px solid rgb(40, 204, 217);
            border-radius: 20px;
            box-shadow: rgb(40, 204, 217) 0px 1px 6px;
            cursor: pointer;
        }
        .lonlat-map {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
