<template>
    <div class="equip-map-content">
        <Mymap class="equip-map" @initmap="initMap" :mapData="mapData"></Mymap>
        <div class="equip-map-legend">
            <div class="equip-legend-title">
                <div>图</div>
                <div>例</div>
            </div>
            <div
                v-for="(item, index) in legendList"
                :key="index"
                class="equip-legend-item"
            >
                <img class="equip-legend-icon" :src="item.URL" />
                <label class="equip-legend-label">{{ item.YJDJNAME }}</label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import "@libs/leaflet_plug/LeafletMarkercluster/leaflet.markercluster.js";
import "@libs/leaflet_plug/LeafletMarkercluster/MarkerCluster.css";
import "@libs/leaflet_plug/LeafletMarkercluster/MarkerCluster.Default.css";
export default {
    name: "map",
    props: {
        mapPoint: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            mapData: {
                mapTypeBtn: false,
                dxtState: true,
                areaState: true,
            },
            basicMap: null,
            markerGroup: null,
            legendList: [
                {
                    URL: require("@image/monitoring_cloud/warn-0.png"),
                    YJDJNAME: "一级预警",
                },
                {
                    URL: require("@image/monitoring_cloud/warn-1.png"),
                    YJDJNAME: "二级预警",
                },
                {
                    URL: require("@image/monitoring_cloud/warn-2.png"),
                    YJDJNAME: "三级预警",
                },
                {
                    URL: require("@image/monitoring_cloud/warn-3.png"),
                    YJDJNAME: "四级预警",
                },
            ],
        };
    },
    watch: {
        mapPoint() {
            this.initMapPoint();
        },
    },
    mounted() {
        this.initCluster();
        this.initMapPoint();
    },
    methods: {
        ...mapActions([]),
        initMap(map) {
            this.basicMap = map;
        },
        initCluster() {
            // 初始化聚合图层
            let map = this.basicMap;
            let selectID = this.selectID;
            let markers = window.L.markerClusterGroup({
                maxClusterRadius: 20,
                spiderfyOnMaxZoom: false,
                showCoverageOnHover: false,
                zoomToBoundsOnClick: false,
                iconCreateFunction: (cluster) => {
                    let da = cluster.getAllChildMarkers();
                    let warn = 1;
                    da.forEach(function (element, index) {
                        if (element.data.YJSFJC === 0) {
                            warn = 0;
                        }
                    });
                    let css = warn
                        ? "markerSelected"
                        : "css_animation markerSelected";
                    let html1 = `<div class='${css}' style='background-image:url("@image/monitoring_cloud/warn${warn}.png")'></div>`;
                    let html2 =
                        "<div class='icon-number'>" +
                        cluster.getChildCount() +
                        "</div>";
                    let options = {
                        html: "<div>" + html1 + html2 + "</div>",
                        iconAnchor: [8, 19] || [15, 34],
                        selfType: "cluster",
                    };

                    for (let i = 0; i < da.length; i++) {
                        if (selectID === da[i].data.ID) {
                            options.className = "selectedAnimation";
                            break;
                        }
                    }
                    return window.L.divIcon(options);
                },
            });
            this.markers = markers;
            map.addLayer(markers);
        },
        async initMapPoint() {
            let map = this.basicMap;
            if (this.markerGroup) {
                this.markerGroup.clearLayers();
            }
            this.markerGroup = window.L.layerGroup([]);
            this.markerGroup.addTo(map);
            let minJD = "";
            let minWD = "";
            let maxJD = "";
            let maxWD = "";
            if (this.mapPoint && this.mapPoint.length > 0) {
                this.mapPoint.forEach((item, i) => {
                    if (item.JD && item.WD) {
                        if (!minJD) {
                            minJD = item.JD;
                        } else {
                            minJD = item.JD < minJD ? item.JD : minJD;
                        }

                        if (!maxJD) {
                            maxJD = item.JD;
                        } else {
                            maxJD = item.JD > maxJD ? item.JD : maxJD;
                        }

                        if (!minWD) {
                            minWD = item.WD;
                        } else {
                            minWD = item.WD < minWD ? item.WD : minWD;
                        }

                        if (!maxWD) {
                            maxWD = item.WD;
                        } else {
                            maxWD = item.WD > maxWD ? item.WD : maxWD;
                        }

                        let myIcon = window.L.icon({
                            iconUrl: require(`@image/monitoring_cloud/warn-${item.YJDJ}.png`),
                            iconSize: [30, 30],
                            iconAnchor: [15, 15],
                        });
                        let marker = window.L.marker([item.WD, item.JD], {
                            icon: myIcon,
                            attribution: item,
                        });
                        this.markerGroup.addLayer(marker);
                        marker.data = item;
                        this.markerGroup.addLayer(marker);
                        if (item.YCJLLIST && item.YCJLLIST.length) {
                            let divIcon = window.L.divIcon({
                                className: "my-div-icon",
                                html:
                                    `<div style="width:16px;height:16px;background:red;color:#fff;border-radius:50%;line-height: 16px;text-align:center;">` +
                                    item.YCJLLIST.length +
                                    "</div>",
                                iconAnchor: [-3, 38],
                            });
                            let numMarker = window.L.marker(
                                [item.WD, item.JD],
                                {
                                    icon: divIcon,
                                }
                            );
                            this.markerGroup.addLayer(numMarker);
                        }
                    }
                });
                this.basicMap.setMinZoom(8).fitBounds([
                    [minWD, minJD],
                    [maxWD, maxJD],
                ]);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.equip-map-content {
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
    // margin-top: 10px;
}
.equip-map-legend {
    position: absolute;
    left: 18px;
    bottom: 18px;
    padding: 16px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: -2px 2px 5px #ccc;
    z-index: 400;
}
.equip-legend-title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    text-align: center;
    line-height: 30px;
}
.equip-legend-item {
    display: flex;
    flex-flow: row nowrap;
    margin: 5px 0 0 0;
    .equip-legend-icon {
        width: 20px;
        height: 20px;
    }
    .equip-legend-label {
        margin: 0 0 0 10px;
        letter-spacing: 2px;
        font-size: 13px;
    }
}
</style>
