const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "预警事件",
                prop: "YJSJ",
            }, {
                label: "预警等级",
                prop: "YJDJ",
                width: "100",
                transList: {
                    '0': "一级预警",
                    '1': "二级预警",
                    '2': "三级预警",
                    '3': "四级预警"
                },
                colorList: {
                    '0': "#ff0000",
                    '1': "#ff6600",
                    '2': "#ffcc00",
                    '3': "#0099ff"
                },
            }, {
                label: "触发时间",
                prop: "FSYJSJ",
                width: "180",
            }, {
                label: "触警项",
                prop: "YJDXMC",
            }, {
                label: "预警预案",
                prop: "YAMC",
                textFile: true,
                URL: "WJLJ",
            }, {
                label: "触警数据",
                prop: "CJSJ",
                width: "90",
                isReplaceText: true,
                replaceText: '查看',
                replaceChar: '查看',
                textStyle: {
                    color: '#0066ff',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                },
                operateType: "columnText"
            }, {
                label: "预警状态",
                prop: "YJZTNAME",
            }, {
                label: "指导建议",
                prop: "",
            }, {
                label: "是否误报",
                prop: "SFYXYJ",
                width: "90",
                transList: {
                    0: '是',
                    1: '否',
                },
            }],
            // 操作列
            columnsBtn: {
                buttonData: [{
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnRead',
                    title: '查看',
                    icon: 'el-icon-view',
                    color: 'green',
                    showMethod: 'showReadBtn',
                }, {
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnEdit',
                    title: '处置',
                    icon: 'el-icon-setting',
                    color: 'green',
                    showMethod: 'showEditBtn',
                }, {
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnMap',
                    title: '地图定位',
                    icon: 'el-icon-map-location',
                    color: 'green',
                    showMethod: 'showMapBtn',
                }, {
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnDelete',
                    title: '删除',
                    icon: 'el-icon-delete',
                    color: 'red',
                    showMethod: 'showDelBtn',
                }],
                width: 150,
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        selectData: [{
            label: '触警项',
            clearable: true,
            placeholder: "请选择触警项",
            list: [],
            optionLabel: 'YJDXMC',
            optionValue: 'ID',
            value: 'YJDX',
            operateType: 'search',
            isShow: true
        }, {
            //     label: '采集人',
            //     clearable: true,
            //     placeholder: "请选择采集人",
            //     list: [],
            //     optionLabel: 'REALNAME',
            //     optionValue: 'ID',
            //     value: 'CJRID',
            //     operateType: 'search',
            //     isShow: true
            // }, {
            label: '状态',
            clearable: true,
            placeholder: "请选择状态",
            list: [],
            optionLabel: 'NAME',
            optionValue: 'CODE',
            value: 'YJZT',
            operateType: 'search',
            isShow: true
        }],
        dateData: [{
            placeholder: "请选择开始时间",
            value: 'startTime',
            operateType: 'search',
            isShow: true
        }, {
            placeholder: "请选择结束时间",
            value: 'endTime',
            operateType: 'search',
            isShow: true
        }]
    },
    snButton: {
        buttonData: [{
            isShow: true,
            btnType: 'button',
            operateType: 'buttonMap',
            name: '地图视图',
            round: true,
            backColor: '#28ccd9',
        }, {
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export { selfData };