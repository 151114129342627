<template>
    <!-- 第一有现场照片的枚举类，例如移动端采集的病害监测数据等 -->
    <!-- 第二无现场照片的枚举类，例如分析评估的预警 -->
    <!-- 第三数值类，例如裂缝、位移等设备接入数据等 -->
    <div class="cjsj-detail-base">
        <div class="cjsj-content">
            <div class="cjsj-item">
                <label class="cjsj-label">触警对象</label>：
                <span class="cjsj-value">{{ cjsjData.YJDXMC }}</span>
            </div>
            <div class="cjsj-item">
                <label class="cjsj-label">触警指标</label>：
                <span class="cjsj-value">{{ cjsjData.YJZBXZJGZSMC }}</span>
            </div>
            <div v-if="sjlx === 1" class="cjsj-item">
                <label class="cjsj-label">触警公式</label>：
                <span class="cjsj-value">{{ cjsjData.PGJG }}</span>
            </div>
            <div class="cjsj-item">
                <label class="cjsj-label">触警值</label>：
                <span class="cjsj-value">{{ cjsjData.CJSJVALUE }}</span>
            </div>
            <div v-if="sjlx === 1" class="cjsj-item-table">
                <label class="cjsj-label">原始数据</label>：
                <el-table
                    :data="yssjList"
                    max-height="250"
                    size="small"
                    class="yjsb-table cjsj-value"
                >
                    <el-table-column
                        v-for="(item, index) in columns"
                        :key="index"
                        :prop="item.prop"
                        :label="item.label"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div v-if="sjlx === 2 && xczpUrl" class="xczp-border">
            <el-image
                :src="xczpUrl"
                fit="cover"
                style="width: 100%; height: 100%"
                class="pointer"
                v-viewer
            >
                <div slot="error" style="width: 100%; height: 100%">
                    <SnNoData></SnNoData>
                </div>
            </el-image>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    props: {
        dataSource: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            xczpUrl: "",
            cjsjData: {},
            yssjList: [],
            sjlx: -1,
            cjzValue: "", // 触警值
            columns: [],
        };
    },
    watch: {
        dataSource() {
            if (this.dataSource) {
                this.getCjsjData();
            }
        },
    },
    mounted() {
        if (this.dataSource) {
            this.getCjsjData();
        }
    },
    methods: {
        ...mapActions(["GetCjsjData"]),
        async getCjsjData() {
            this.cjsjData = {};
            this.cjzValue = "";
            this.yssjList = [];
            this.xczpUrl = "";
            this.columns = [];
            if (!this.dataSource) return;
            this.sjlx = Number(this.dataSource.SJLX);
            let yjsjId = this.dataSource.ID;
            let sjlx = this.sjlx;
            let result = await this.GetCjsjData({
                yjsjId: yjsjId,
                sjlx: sjlx, // 数据类型; 1:数值类 2:枚举类
            });
            if (result) {
                if (result.ENTINFO && result.ENTINFO.length > 0) {
                    this.cjsjData = result.ENTINFO[0];
                    if (this.sjlx === 2) {
                        this.cjzValue = this.cjsjData.CJSJVALUE;
                        if (this.cjsjData.PIC && this.cjsjData.PIC.length > 0) {
                            this.xczpUrl = this.cjsjData.PIC[0].CCLJ;
                        }
                    } else {
                        if (this.cjsjData.CJSJVALUE) {
                            let regExp = new RegExp("[0-9,]", "g");
                            let cjzArr = this.cjsjData.CJSJVALUE.match(regExp);
                            this.cjzValue = cjzArr.join("");
                        }
                    }
                }
                if (this.sjlx === 1 && result.DATA && result.DATA.length > 0) {
                    let tableData = result.DATA;
                    tableData.forEach((item, index) => {
                        let obj = {};
                        obj.ID = item.ID;
                        obj.CJSJ = item.CJSJ;
                        if (index === 0) {
                            this.columns.push({
                                label: "时间",
                                prop: "CJSJ",
                            });
                        }
                        if (item.VALUE) {
                            let cjxArr = item.VALUE.split(",");
                            if (cjxArr && cjxArr.length > 0) {
                                cjxArr.forEach((item1, index1) => {
                                    let key = `VALUE${index1}`;
                                    let cjxItemArr = item1.split("=");
                                    obj[key] = cjxItemArr[1];
                                    if (index === 0) {
                                        this.columns.push({
                                            label: cjxItemArr[0],
                                            prop: key,
                                        });
                                    }
                                });
                            }
                        }
                        this.yssjList.push(obj);
                    });
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.cjsj-detail-base {
    position: relative;
    width: 100%;
    min-height: 170px;
    max-height: 700px;
    overflow: auto;
    .cjsj-content {
        .cjsj-item {
            width: calc(100% - 185px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .cjsj-item-table {
            display: flex;
            width: 600px;
            max-width: calc(100% - 185px);
        }
        .cjsj-label {
            width: 60px;
            height: 35px;
            text-align-last: justify;
        }
        .cjsj-value {
            margin-left: 10px;
        }
    }
    .xczp-border {
        position: absolute;
        top: 0;
        right: 0;
        width: 165px;
        height: 165px;
    }
}
</style>
